<template>
    <div class="totalCon">
        <div class="wrpaCon total">
            <div class="sin">
                <p class="txt">总藏书量</p>
                <p class="num yellow">{{info.bookTotal}}</p>
            </div>
            <div class="sin">
                <p class="txt">总借阅量</p>
                <p class="num blue">{{info.borrowTotal}}</p>
            </div>
            <div class="sin">
                <p class="txt">读者总数</p>
                <p class="num red">{{info.readerTotal}}</p>
            </div>
        </div>
        <img class="botBg" src="../../../assets/images/base_data@2x.png" alt="">
        <div class="wrpaCon type">
            <div class="sin">
                <p class="txt">当前阅读</p>
                <p class="num yellow">{{info.monthBorrow}}</p>
            </div>
            <div class="sin">
                <p class="txt">今日借阅</p>
                <p class="num blue">{{info.todayBorrow}}</p>
            </div>
            <div class="sin">
                <p class="txt">当月借阅</p>
                <p class="num red">{{info.monthBorrow}}</p>
            </div>
            <div class="sin">
                <p class="txt">年度借阅</p>
                <p class="num white">{{info.yearBorrow}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {getOverView} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            info: {}
        }
    },
    methods: {
    },
    mounted() {
        if (!this.syncId) return
        getOverView({syncId: this.syncId}).then(res => {
            this.info = res.data
        })
    },
}
</script>
<style lang="less">
.totalCon{
    .total{
        padding: 0 2.6rem;
        padding-top: 0.2rem;
        .sin{
            width: 5.6em;
            height: 7.8em;
            display: flex;
            align-items: center;
            flex-direction: column;
            &:first-child{
                margin-left: 0;
            }
            .txt{
                color: #fff;
                font-size: 0.9em;
            }
            .num{
                padding-top: 0.05em;
                font-size: 1.2em;
                letter-spacing: 2px;
            }
            .yellow {
                color: #FFCD3E;
            }
            .red {
                color: #FF4A22;
            }
            .blue{
                color: #3EF3FF;
            }
            &:first-child{
                background: url(../../../assets/images/icon_book@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(2){
                background: url(../../../assets/images/icon_borrow@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(3){
                background: url(../../../assets/images/icon_reader@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
        }
    }
    .botBg{
        width: 100%!important;
        height: auto;
        position: relative;
        top: -2.5em;
    }
    .type{
        .sin{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0.3em 0;
            .txt{
                color: #fff;
                font-size: 0.8em;
            }
            .num{
                font-size: 1.3em;
            }
            &:first-child {
                background: url(../../../assets/images/bg_yellow@2x.png) no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(2) {
                background: url(../../../assets/images/bg_green@2x.png) no-repeat center;
                background-size: 100% 100%;
                margin-left: 1em;
            }
            &:nth-child(3) {
                background: url(../../../assets/images/bg_red@2x.png) no-repeat center;
                background-size: 100% 100%;
                margin-left: 1em;
            }
            &:nth-child(4) {
                background: url(../../../assets/images/bg_white@2x.png) no-repeat center;
                background-size: 100% 100%;
                margin-left: 1em;
            }
            .yellow {
                color: #FFCD3E;
            }
            .red {
                color: #FF4A22;
            }
            .blue{
                color: #3EF3FF;
            }
            .white{
                color: #B1DEFF;
            }
        }
    }
    .wrpaCon{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}
</style>